import axios from "axios";
const axiosApi = axios.create();

// Request interceptor for API calls
axiosApi.interceptors.request.use(
    async config => {

      config.headers = { 
        'Authorization': `Bearer ` + localStorage.getItem('token'),
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Request-Private-Network': 'true',
        // 'Access-Control-Allow-Headers':'Origin,Authorization, Content-Type, X-Auth-Token,X-Requested-With',
      }
      return config;
    },
    error => {
      Promise.reject(error)
  });
  
  // Response interceptor for API calls
  axiosApi.interceptors.response.use((response) => {
    // console.log('axios response' , response)
    
    if (response.data.nuevotoken){
      // console.log('viene token nuevo lo actualizamos')
       localStorage.setItem("token", (response.data.nuevotoken))
    }
    return response
  },
  error=>{
    console.log(error)
    if (error.response.status===401){
      (console.log('Token expirado?'))
      localStorage.removeItem("usuario");
      localStorage.removeItem("token");
      // Pendiente de comprobar token expirado
      //  window.location.reload()

    }
     if (error.response.status === 403) {
         console.log("403");
         return("403")
        //  localStorage.removeItem("usuario");
        //  localStorage.removeItem("token");
         // Pendiente de comprobar token expirado
         //  window.location.reload()
     }

  }
  );

const exported= {
  axiosApi,
}

  export default exported