import Ventaseurostienda from "./../component/VentasEurosTienda";
import Clientestienda from "./../component/ClientesTienda";
import DataGdridVentasClienets from "./../component/DataGridVentasClientes";
import authService from "../servicios/auth.service";
import Margenes from "../component/Margenes";
import { Box, Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
// import roles from "../recursos/tipousuarios";
import React, { useState } from "react";

function About() {
  // console.warn(roles);
  const [esAdministrador, setEsAdmininistrador] = useState(
    authService.getCurrentUser().rol === "admin"
  );
  const [esEncargado, setEsEncargado] = useState(
    authService.getCurrentUser().rol === "encargado"
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      maxWidth="99%"
    >
      {" "}
      {/* Solo visible para administradores */}
     
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          maxWidth="85%"
          // sx={{ flexGrow: 1, width: '90%' }}
        >
          <Grid
            container
            spacing={8}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* <Grid item xs={12} sm={12}>
              <Margenes> </Margenes>{" "}
            </Grid>{" "} */}
            <Grid item xs={12} sm={4}>
              <Ventaseurostienda codalm="V" />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <DataGdridVentasClienets codalm="V" />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Clientestienda codalm="V" />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Ventaseurostienda codalm="S" />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <DataGdridVentasClienets codalm="S" />
            </Grid>{" "}
            <Grid item xs={12} sm={4}>
              <Clientestienda codalm="S" />
            </Grid>{" "}
          </Grid>{" "}
        </Box>
     
      {
        esEncargado ?
        // Solo visible para encargados
        (<></>)
        :
        (<></>)
      }
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          // display: 'inline-flex',
          // justifyContent: 'flex-start',
          p: 1,
          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      ></Box>{" "}
    </Box>
  );
}
export default About;
