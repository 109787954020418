import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { MDBSpinner } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosApi from "../servicios/axios.service";
import Gestiondeurl from "../servicios/url.service";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GridFilterPanel } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import SmartDataTable from "react-smart-data-table";
// import "react-smart-data-table/dist/react-smart-data-table.css";
const useFakeMutation = () => {
  return useCallback(
    (user) =>
      new Promise(async (resolve, reject) => {
        let data = {
          codcli: user.id,
          rol: user.rol,
        };
        const urlbase = await Gestiondeurl.DevuelveUrl();
        // console.log(urlbase);
        let url = urlbase + "admin/updateusuariorol";
        // let url = 'http://192.168.1.60/api/react/admin/updateusuariorol'
        // setCargando(true)
        AxiosApi.axiosApi
          .post(url, data)
          .then((response) => {
            // console.log(response);
            if (response.data.response === "ok") {
              // setCargando(false)
              resolve({ ...user });
              // setSnackbar({ children: 'User successfully saved', severity: 'success' });
            }

            return response;
          })
          .catch((error) => {
            // setCargando(false)
            console.log(error);
          });
      }),

    []
  );
};

function Usuarios(props) {
  const parseRolUsuario = function (value) {
    console.log(value);

    return String(value);
  };
  const theme = createTheme(
    {
      palette: {
        // type: 'light',
        primary: {
          main: "#e3007e",
        },
        secondary: {
          main: "#f50057",
        },
      },
    },
    esES // x-data-grid translations
  );
  const clickenfila = (params) => {
    // console.log(params.row);
    props.setCodcli(params.row.id);
  };
  const mutateRow = useFakeMutation();
  // console.log(tema)
  const [snackbar, setSnackbar] = useState(null);
  // const [filt, setFilt] = useState([]);
  const [pageSize, setPageSize] = React.useState(25);
  const handleCloseSnackbar = () => setSnackbar(null);
  const processRowUpdate = useCallback(
    async (newRow) => {
      // console.log(newRow);

      // Make the HTTP request to save in the backend
      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Usuario actualizado correctamente",
        severity: "success",
      });
      return response;
    },
    [mutateRow]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  const enlacedetalle = (p) => {
    // console.log(p.row.mes.toString().length)
    if (p.row.mes && p.row.mes.toString().length < 2) {
      p.row.mes = "0" + p.row.mes;
    }
    return (
      <Link to={`/detallemes/${p.row.Año}/${p.row.mes}/${props.codalm}`}>
        {p.value}
      </Link>
    );
  };
  const [fechainicio, setFechainicio] = useState(
    DateTime.local().startOf("month").toFormat("yyyyMMdd")
  );
  const [fechafin, setFechafin] = useState(
    DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
  );
  const [value, setValue] = useState(props.periodo);
  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  const getMargen = (ventas, coste) => {
    const beneficio = ventas - coste;
    let margen = beneficio / ventas;
    margen = Number(margen * 100).toLocaleString();
    return `${margen} %`;
  };
  const getVentas = (params) => {
    // console.log (params.row)

    return (
      params.row.ventastpv +
      params.row.ventasfacturas +
      params.row.ventasonline +
      params.row.ventasalbaranes +
      params.row.ventasinternas
    );
  };
  function parseRol(params) {
    // console.log(params);
    // console.log(params.field);
    const colDef = params.api.getColumn(params.field);
    // console.log(colDef.valueOptions);
    const option2 = colDef.valueOptions.find((valor) => {
      // console.log(valor);
      return valor.value === params.value;
    });
    const option = colDef.valueOptions.find(
      ({ value: optionValue }) => params.value === optionValue
    );
    // console.log(option2);
    return option2.label;
  }
  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    { field: "id", headerName: "Código", flex: 1 },
    { field: "nomcli", headerName: "Nombre", flex: 3 },
    {
      field: "rol",
      headerName: "Rol",
      flex: 1,
      type: "singleSelect",
      // disableColumnMenu:true,

      valueOptions: [
        { value: 0, label: "Inactivo" },
        { value: 10, label: "Usuario" },
        { value: 1, label: "Administrador" },
        { value: 2, label: "Encargado" },
        { value: 3, label: "Oficina/Ventas" },
      ],
      valueGetter: parseRol,
      editable: true,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        // console.log("colDef", colDef);
        // console.log("value", value);
        // console.log("id", rowId);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );
        const option2 = colDef.valueOptions.find((valor) => {
          // console.log(valor);
          return valor.label === value;
        });

        // return option2.label;
      },
      // valueParser: (value, params) => {
      //   console.log("valueparser", value, params);

      //   return String(value);
      // },
    },
  ];

  const rows = [{}, {}];
  const [cargando, setCargando] = useState(true);

  const [filas, setFilas] = useState({});
  const numberFormat = (value) => {
    const valueFormatted = Number(value * 1).toLocaleString();
    return `${valueFormatted} €`;
  };
const clickenfilatabla = (event, { rowData, rowIndex, tableData }) => {
  // The following results should be identical
  console.log(rowData, tableData[rowIndex]);
  props.setCodcli(rowData.id);
};
  useEffect(() => {
    const getDatos = async () => {
      setCargando(true);
      let et = [];
      let v = [];
      let c = [];
      const urlbase = await Gestiondeurl.DevuelveUrl();
      let url = urlbase + "admin/listausuarios";
      // let url = 'http://192.168.1.60/api/react/' + 'admin/listausuarios'
      // console.log(DateTime.fromFormat(fechainicio, 'yyyyMMdd').setLocale('es').toFormat('dd - MMMM - yyyy'), fechafin)
      let data = {
        fechainicio: fechainicio,
        fechafin: fechafin,
        centrocoste: props.centrodecoste,
      };
      let config = {
        headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
      };
      AxiosApi.axiosApi
        .post(url, data)
        .then((response) => {
          // console.log(response.data);

          setFilas(response.data.response);
          setCargando(false);
        })
        .catch((error) => {
          setCargando(false);
          console.log(error);
        });
    };
    getDatos();
  }, [value]);
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
    height: "600px",
    width: "80%",
  };
  return (
    <>
      <></>

      {!cargando ? (
        <ThemeProvider theme={theme}>
          <div
            sx={{ m: 2 }}
            style={{
              margin: "2em",
              width: "650",
              height: "800px",
              maxHeight: "800px",
            }}
          >
            {/* <Button
              onClick={() =>
                setFilt([
                  {
                    columnField: "rol",
                    operatorValue: "is",
                    value: "Administrador",
                  },
                ])
              }
            >
              Mostrar Administradores
            </Button>
            <Button onClick={() => setFilt([])}>Vaciar Filtro</Button> */}
            {/* <DataGrid
              // className="Datagrid-rotado"
              // headerHeight={123}
              rows={filas}
              density="compact"
              columns={columns}
              experimentalFeatures={{ newEditingApi: true }}
              rowsPerPageOptions={[5, 10, 15, 25, 100]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              pageSize={pageSize} // Comentar esta línea para poder imprimir todos los registros
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {},
                },
              }}
              // filterModel={{
              //   items: filt,
              // }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              onRowClick={clickenfila}
              // onCellEditStop={(params, event) => {
              //   console.log('params',params)
              //   console.log(event)
              //   // if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              //   //   event.defaultMuiPrevented = true;
              //   // }
              // }}
            /> */}
            <SmartDataTable
              data={filas}
              name="test-table"
              className="ui compact selectable table"
              sortable = "true"
              filterable ="true"
              perPage={25}
              onRowClick={clickenfilatabla}
            />
            {!!snackbar && (
              <Snackbar
                open
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
              >
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
          </div>
        </ThemeProvider>
      ) : (
        // <CircularProgress/>
        <MDBSpinner className="mx-2" color="primary"></MDBSpinner>
      )}
    </>
  );
}

export default Usuarios;
