import axios from "axios";
import md5 from 'md5-hash'
import jwt_decode from "jwt-decode";
import Gestiondeurl from './url.service'

// const API_URL = "http://192.168.1.59:8000/api/auth/";
// const API_URL = "http://192.168.1.60/api/react/";
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*'
}
// const register = (name, email, password) => {
//   return axios.post(API_URL + "signup", {
//     name,
//     email,
//     password,
//   });
// };
const centro = async ()=>{
  // let localizacion = "FUERA DE DIFERENTE";
  const res = await axios.get("https://api.ipify.org/?format=json");
  console.log(res.data.ip);
  switch (res.data.ip) {
    case "80.38.219.243":
      return("V");
      break;
    case "88.10.196.152":
      return("S");
      break;
    case "90.174.90.152":
      return("A");
      break;
    default:
      return(0);
  }

}
const login = async (usuario, password) => {
  const urlbase = await Gestiondeurl.DevuelveUrl()
  console.log(urlbase)
  return axios
    .post('http://antonio.diferente.es/api/auth/login',
   
    {
      
      "usuario": usuario,
      "password": (password),
    },{
      headers:headers
    })
    .then((response) => {
      console.log("response", response.data)
      if (response.data.token) {
        var token = (response.data.token);
        var nombre=response.data.nombre
        console.log('token=>',token)
        localStorage.setItem("usuario", (nombre));
        localStorage.setItem("token", (token));

      }

      return (response.data);

    });
};

const logout = () => {
  localStorage.removeItem("usuario");
  localStorage.removeItem("token");
};

const getCurrentUser = () => {
  if (localStorage.getItem("usuario") === null) {
    return ({
      "id": "00000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })
  }
  // console.log("getcurrentuser", localStorage.getItem("usuario"))
  if (localStorage.getItem("usuario")) {
    return (localStorage.getItem("usuario"));
  } else {
    return ({
      "id": "000000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })

  };
}
const tiempoHastaExpirar = ()=>{
  // console.log((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
  return ((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
}
const comprobarToken = () => {
  // console.log('Llamada a comprobarToken')
  if (localStorage.getItem("token")) {

    // console.log('expiration ' + jwt_decode(localStorage.getItem("token")).exp + ' | ' + Date.now() / 1000)
    // console.log('Token expirado=>'+jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    // return (jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    return false
  } else {
    // No hay token
    console.log('No hay Token')
    return true
  }

}
const actualizarToken = () => {
  if (localStorage.getItem("token")) {

    console.log('expiration ' + jwt_decode(localStorage.getItem("token")).exp + ' | ' + Date.now() / 1000)
    console.log(jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
  }


}

const comprobarRol = () => {
  const usuario = JSON.parse(localStorage.getItem('usuario'))
  console.log('rol del usuario' + usuario)
  return (usuario.rol)
}
const exportar= {
  // register,
  login,
  logout,
  getCurrentUser,
  comprobarToken,
  actualizarToken,
  comprobarRol,
  tiempoHastaExpirar,
  centro,
};
export default exportar