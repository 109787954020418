import Usuarios from "../component/Usuarios.administracion";
import DetalleUsuario from "../component/Usuario.detalle";
import FichajesUsuario from "../component/Usuario.fichajes";
// import Margenes from "../component/Margenes";
import { Box, Grid, Paper } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { useState } from "react";
function PaginaUsuarios() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
 const [codcli, setCodcli]=useState()
  return (
    <Box
      // container
      spacing={8}
      display="flex"
      // justifyContent="center"
      alignItems="flex-start"
      width="99%"
      flex="1"
      // xl="14"
      sx={{ m: 2}}
      // maxWidth={false}
      // rowSpacing={1}
      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <>
        <Grid sx={{ m: 2 }} item xs={12} sm={12} md={4}>
          <Usuarios setCodcli={setCodcli} />
        </Grid>
        <Grid sx={{ m: 2 }} item xs={12} sm={12} md={5}>
          {/* <DetalleUsuario codcli={codcli} /> */}
          <FichajesUsuario codcli={codcli} />
        </Grid>
        <Grid sx={{ m: 2 }} item xs={12} sm={6} md={3}>
          <DetalleUsuario codcli={codcli} />
          {/* <FichajesUsuario codcli={codcli} /> */}
        </Grid>

        

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            // display: 'inline-flex',
            // justifyContent: 'flex-start',
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        ></Box>
      </>
      //{" "}
    </Box>
  );
}
export default PaginaUsuarios;