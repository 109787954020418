import React, { useEffect, useState, lazy,Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// import { createTheme, ThemeProvider } from '@material-ui/core';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar2 from "./component/Navbar2";
import NavbarUsuarios from "./component/NavbarUsuarios";
import authService from "./servicios/auth.service";
// import DetalleMes from "./pages/detallemes";
import Home from "./pages/home";
import Login from "./pages/Login";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";
//  import "./recursos/bootstrap.min.css";
import "./App.css";
import "./custom.scss";
import "./recursos/Semantic-UI-CSS-master/semantic.min.css"
import Margenes from "./pages/margenes";
import PaginaUsuarios from "./pages/Usuarios";
// import Articulos from "./pages/Articulos";
// import Clientes from "./pages/Clientes";

const Clientes = lazy(()=> import("./pages/Clientes"))
const DetalleMes = lazy(()=> import("./pages/detallemes"))
const Articulos = lazy(()=> import("./pages/Articulos"))
const Perfil = lazy(()=> import("./pages/Perfil"))
const FichajesAdmin = lazy(() => import("./pages/FichajesAdmin"));
// import {
//   default as AuthService,
//   default as authService,
// } from "./servicios/auth.service";
const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: "#e3007e",
    },
    secondary: {
      main: "#f50057",
    },
  },
});
function App() {
  // const [esAdministrador, setEsAdmininistrador] = useState(
  //   authService.getCurrentUser().rol === "admin"
  // );
  // const [esEncargado, setEsEncargado] = useState(
  //   authService.getCurrentUser().rol === "encargado"
  // );
  // const [esUsuario, setEsUsuarioo] = useState(
  //   authService.getCurrentUser().rol === "user"
  // );
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  const [barra, setBarra] = useState(false);
  let usuario = {};
  // console.log(location.pathname)
  // if (currentUser) {
  //   console.log("usuario", usuario)
  //   setCurrentUser(usuario);
  //   console.log("currentUser", currentUser)
  //   // navigate("/login");

  // } else {

  //   console.log('no hay usuario')
  //   if (location.pathname != '/login') {
  //       navigate("/login");
  //   }

  // }
  authService.comprobarToken();
  useEffect(
    (props) => {
      usuario = authService.getCurrentUser();
      if (location.pathname !=="/login") {
        setBarra(true);
      } else {
        setBarra(false);
      }

      setCurrentUser(usuario);
      if (usuario) {
        console.log("hay usuario", usuario);
        // setCurrentUser(usuario);
        console.log("currentUser", currentUser);
        // navigate("/login");
        if (authService.comprobarToken()) {
          console.log("Token expirado o no hay token");
          if (location.pathname != "login") {
            navigate("/login");
          }
        }
      } else {
        console.log("no hay usuario");
        if (location.pathname != "login") {
          navigate("/login");
        }
      }
    },
    [location.pathname]
  );

  const logOut = () => {
    authService.logout();
  };

  return (
      <ThemeProvider theme={theme}>
          {barra ? <Navbar2></Navbar2> : <></>}
          {/* <Router> */}
          <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                  <Route exact path={"/"} element={<Home />} />
                  <Route
                      path={"/margenescentrodecoste"}
                      element={<Margenes />}
                  />
                  <Route path={"/usuarios"} element={<PaginaUsuarios />} />
                  <Route path={"/clientes"} element={<Clientes />} />
                  <Route path={"/articulos"} element={<Articulos />} />
                  <Route
                      path={"/detallemes/:ano/:mes/:tienda"}
                      element={<DetalleMes />}
                  />
                  <Route path={"/detallemes"} element={<DetalleMes />} />
                  <Route path={"/login"} element={<Login />} />
                  <Route path={"/perfil"} element={<Perfil />} />
                  <Route path={"/fichajesadmin"} element={<FichajesAdmin />} />
              </Routes>
          </Suspense>
          {/* </Router> */}
      </ThemeProvider>
  );
}
export default App;
