import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid, gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { Interval, DateTime, Duration, days } from "luxon";
import { MDBSpinner } from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import AxiosApi from "../servicios/axios.service";
import Gestiondeurl from "../servicios/url.service";
import SelectorFecha from "./SelectorFechas";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

function FichajesUsuario(props) {
  // console.log(props);
  const StyledTableCell = styled(TableCell)({
    padding: 1,
  });
  const cambioperiodo = (fechainicio, fechafin) => {
    console.log(fechainicio, fechafin);
    setFechafin(fechafin);
    setFechainicio(fechainicio);
  };
  const [pageSize, setPageSize] = React.useState(25);
  const filas = {};

  const [cargando, setCargando] = useState(false);
  const [fichajes, setFichajes] = useState([]);
  const [fechainicio, setFechainicio] = useState(
    DateTime.local().startOf("month").toFormat("yyyyMMdd")
  );
  const [fechafin, setFechafin] = useState(
    DateTime.local().endOf("month").toFormat("yyyyMMdd")
  );

  const interval = Interval.fromDateTimes(
    DateTime.fromFormat(fechainicio, "yyyyMMdd"),
    DateTime.fromFormat(fechafin, "yyyyMMdd").plus({ days: 1 })
  )
    .splitBy({ day: 1 })
    .map((d) => d.start);

  // console.log(interval);
  const [value, setValue] = useState(props.periodo);
  const handleChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  const getAllIndexes = (arr, val1, val2) => {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) {
      // console.log(arr[i].dia, val1,val2);
      if (arr[i].dia === val1 && arr[i].mes === val2) {
        indexes.push(i);
      }
    }
    return indexes;
  };
  useEffect(() => {
    const getDatos = async () => {
      setCargando(true);
      const urlbase = await Gestiondeurl.DevuelveUrl();
      let url = urlbase + "admin/fichajesusuario";
      let data = {
        codcli: props.codcli,
        fechainicio: fechainicio,
        fechafin: fechafin,
      };
      if (props.codcli) {
        AxiosApi.axiosApi
          .post(url, data)
          .then((response) => {
            console.log(response.data);
            if (response.data.response.length == 0) {
              setFichajes([]);
            }
            let fichajesdiarios = [];
            let i = 0;
            interval.forEach((f) => {
              // console.log(f.toFormat("dd", { locale: "es" }));
              let fichaje = {};
              let indices = getAllIndexes(
                response.data.response,
                f.toFormat("dd", { locale: "es" }),
                f.toFormat("MM", { locale: "es" })
              );
              // console.log(
              //   ` El día ${f.toFormat("dd", { locale: "es" })} hay ${
              //     indices.length
              //   } registros: ${indices}`
              // );
              if (indices.length > 0) {
                // Hay que procesar los fichajes
                fichaje.dia = f.toFormat("dd 'de' LLLL", { locale: "es" });
                // console.log(indices);
                indices.forEach((value, index) => {
                  let hora = DateTime.fromISO(
                    response.data.response[value].momento
                  );

                  // console.log(hora.hour);
                  if (hora.hour < 17) {
                    // El registro es de mañana
                    fichaje.centrom = response.data.response[value].Description;
                    switch (response.data.response[value].Registro) {
                      case "Entrada":
                        fichaje.entradamluxon = hora;
                        fichaje.entradam = hora.toFormat("HH:mm:ss");
                        break;
                      case "Salida":
                        fichaje.salidamluxon = hora;
                        fichaje.salidam = hora.toFormat("HH:mm:ss");
                        break;
                    }
                  }
                  if (hora.hour >= 17) {
                    // El registro es de tarde
                    fichaje.centrot = response.data.response[value].Description;
                    switch (response.data.response[value].Registro) {
                      case "Entrada":
                        fichaje.entradatluxon = hora;
                        fichaje.entradat = hora.toFormat("HH:mm:ss");
                        break;
                      case "Salida":
                        fichaje.salidatluxon = hora;
                        fichaje.salidat = hora.toFormat("HH:mm:ss");
                        break;
                    }
                  }
                });
              } else {
                // No hay fichajes, registro vacío
                fichaje.dia = f.toFormat("dd 'de' LLLL", { locale: "es" });
                fichaje.centrom = " --- ";
                fichaje.entradam = " --- ";
                fichaje.salidam = " --- ";
                fichaje.centrot = " --- ";
                fichaje.entradat = " --- ";
                fichaje.salidat = " --- ";
                fichaje.horasm = 0;
                fichaje.horast = 0;
              }
              if (fichaje.entradamluxon && fichaje.salidamluxon) {
                const diff = fichaje.salidamluxon.diff(fichaje.entradamluxon, [
                  "years",
                  "months",
                  "days",
                  "hours",
                ]);
                console.log(diff);
                fichaje.horasm = diff.toObject().hours;
              }
              if (fichaje.entradatluxon && fichaje.salidatluxon) {
                const diff = fichaje.salidatluxon.diff(fichaje.entradatluxon, [
                  "years",
                  "months",
                  "days",
                  "hours",
                ]);
                if (fichaje.entradamluxon && !fichaje.salidamluxon) {
                  // Falta la hora de salida por la mañana
                }
                // console.log('Horas trabajadas de tarde ',diff.toObject().hours);
                fichaje.horast = diff.toObject().hours;
              }
              if (!fichaje.horasm) fichaje.horasm = 0;
              if (!fichaje.horast) fichaje.horast = 0;
              fichaje.horas = fichaje.horasm + fichaje.horast;
              if (fichaje.horas == 0) {
                fichaje.fondo = "teal";
              } else {
                fichaje.fondo = "white";
              }
              if (fichaje.entradamluxon && !fichaje.salidamluxon) {
                // Falta la hora de salida por la mañana
                fichaje.fondo = "orange";
              }
              fichajesdiarios.push(fichaje);
              if (
                response.data.response[i].dia ===
                f.toFormat("dd", { locale: "es" })
              ) {
                console.log(
                  "Trabajo el día " + f.toFormat("dd", { locale: "es" })
                );
              }
            });

            setFichajes(fichajesdiarios);
            setCargando(false);
          })
          .catch((error) => {
            setCargando(false);
            console.log(error);
          });
      } else {
        setCargando(false);
      }
    };
    getDatos();
  }, [props.codcli, fechainicio, fechafin]);
  const mystyle = {
    color: "black",
    backgroundColor: "#e3007e",
    padding: "5px",
    fontFamily: "Arial",
    tr: {
      padding: "20px",
    },
  };
  const sumarHoras = () => {
    let suma = 0;
    fichajes.forEach((value, index) => {
      suma += value.horas;
    });
    // console.log(
    //   Duration.fromObject(
    //     {
    //       hour:suma
    //     }
    //   ).toFormat('hh:mm')
    // )
    return (
      suma.toFixed(2) +
      " (" +
      Duration.fromObject({
        hour: suma,
      }).toFormat("hh 'h':mm 'm'") +
      ")"
    );
  };
  return (
    <>
      <SelectorFecha
        cambioperiodo={cambioperiodo}
        periodo={"estemes"}
      ></SelectorFecha>
      {/* {fichajes && fichajes.length > 0 ? renderFichajes() : <></>} */}
      <h1>Horas trabajadas: {sumarHoras()}</h1>
      <TableContainer 
      sx={{maxHeight:"400px"}}
      component={Paper}>
        <Table
          sx={{ maxWidth: "99%", padding: 1 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ textAlign: "center" }}>
              <TableCell colSpan={1}></TableCell>
              <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                Mañana
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                Tarde
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Centro de Trabajo</TableCell>
              <TableCell>Entrada</TableCell>
              <TableCell>Salida</TableCell>
              <TableCell>Centro de Trabajo</TableCell>
              <TableCell>Entrada</TableCell>
              <TableCell>Salida</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fichajes.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  backgroundColor: row.fondo,
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {/* <TableCell component="th" scope="row">
                  {row.name}
                </TableCell> */}
                <StyledTableCell>{row.dia}</StyledTableCell>
                <TableCell align="center">{row.centrom}</TableCell>
                <TableCell align="center">{row.entradam}</TableCell>
                <TableCell align="center">{row.salidam}</TableCell>
                <TableCell align="center">{row.centrot}</TableCell>
                <TableCell align="center">{row.entradat}</TableCell>
                <TableCell align="center">{row.salidat}</TableCell>
                <TableCell align="center">
                  {(row.horasm + row.horast).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default FichajesUsuario;
